<template>
  <div class="container-fluid d-flex h-100 flex-column">
    <div class="row g-0">
      <div class="col">
        <h5 class="current">Current subscription plan</h5>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row g-0">
          <p class="card-text subscription-plan">{{ billingDetails.subscriptionName }}</p>
        </div>
        <div class="row pb-0 g-0">
          <p class="card-text subscription-price">${{ billingDetails.planPrice }} /
            {{ billingDetails.paymentInterval }}
          </p>
        </div>

        <div class="row g-0" v-if="billingDetails.cancellationDate">
          <p class="card-text subscription-renewal">Your current plan terminates on
            {{ billingDetails.cancellationDate }}</p>
        </div>
        <div class="row g-0" v-else>
          <p class="card-text subscription-renewal">Your plan renews on {{ billingDetails.renewalDate }}
          </p>
        </div>
      </div>
      <div class="col text-end" v-if="billingDetails.cancellationDate">
        <span class="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="left" 
          title="Not implemented. Your current plan is scheduled to be cancelled on the provided date. If you wish to resume the plan please content Authentic Labs support">
          <button class="btn btn-outline-primary btn-sm rounded-pill mx-2" disabled>Resume plan</button>
        </span>
      </div>
      <div class="col pe-1 mt-2 text-end" v-else>
        <div class="btn-group" role="group">
          <button class="btn btn-outline-secondary btn-sm rounded-pill" type="button" @click="cancelPlan">Cancel
            plan</button>
          <button class="btn btn-primary btn-sm rounded-pill mx-2" type="button" @click="updatePlan">Update
            plan</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="row g-0 pt-5 pb-0 current align-self-center" no-gutters>
          <div class="col-5 col-md-3 align-self-center">
            <h5 style="margin-bottom: 0px !important">Payment method</h5>
          </div>
          <div class="col pe-0 my-2 text-end">
            <button class="btn btn-outline-primary btn-sm rounded-pill" @click="addPayment">Add payment method</button>
          </div>
        </div>
      </div>
    </div>

    <div id="overlay" :style="showOverlay ? 'display:flex' : 'display:none'" class="analytics-db-container">
      <div id="text" class="d-flex flex-column align-items-center justify-content-center">
        <div class="row">
          <div class="spinner-border text-primary" role="status">
          </div>
        </div>
        <div class="row">
          <span>{{ paymentMethodMessage }}</span>
        </div>
      </div>
    </div>


    <div class="row g-0 payment-methods">
      <div class="col">
        <table id="table" class="table table-borderless table-sm w-auto">
          <tbody>
            <tr v-for="(card, index) in billingDetails.paymentMethods" :key="index">
              <td width="240"> 
                <span class="pe-1">{{
                  $filters.capitalizeFirst(card.brand)}}
                </span>
                <span class="pe-1"><span>&bull;&bull;&bull;&bull;</span>
                  {{ card.last_four }}</span>
                <span v-if="card.default" class="default">Default</span>
              </td>
              <td width="135"> <span class="pe-1">Expires {{ $filters.formatMonth(card.exp_month as number) }}/{{
                  card.exp_year
                  }}</span>
              </td>
              <td style="z-index: 0;" v-if="!card.default">
                <div class="btn-group">
                  <button type="button" class="btn action-button py-0 px-1"
                    @click.stop="makeDefault(index, card.id as string)" data-bs-toggle="tooltip"
                    :title="'Make this card the default payment method'">
                    <i class="bi bi-check2-circle" style="font-size: 1em;"></i>
                  </button>
                  <button type="button" class="btn action-button py-0 px-1"
                    @click.stop="deletePaymentMethod(index, card.id as string)" data-bs-toggle="tooltip"
                    :title="'Delete payment method'">
                    <i class="bi bi-trash3" style="opacity: 1; font-size: 1em; color:#e3342f"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row pt-4" no-gutters>
      <div class="col">
        <h5 class="current">Billing history</h5>
      </div>
    </div>

    <div class="row" w>
      <div class="col history-container">
        <div class="card border-0 py-2" v-for="(billingRecord, index) in billingDetails.billingHistory" :key="index">
          <div class="row">
            <div class="col-4 col-md-3">
              <p class="card-text">{{ billingRecord.date }} </p>
            </div>
            <div class="col-3 col-md-3 col-lg-2">
              <p class="card-text">{{ billingRecord.amount }} </p>
            </div>
            <div class="col overflow-ellipses">
              <p class="overflow-ellipses card-text" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-variant :title="billingRecord.description">{{
                billingRecord.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {
    getCustomerStatus,
    setDefaultPayment,
    removePayment,
  } from "../../services/PaymentService";
  import User from "../../models/User";
  import Billing from "../../models/Billing";
  import { computed, onBeforeUnmount, ref, inject, onMounted, nextTick, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Tooltip } from "bootstrap";

  const $store = useStore();
  const emitter: any = inject("emitter");
  const tooltipInstances = ref<InstanceType<typeof Tooltip>[]>([]);

  const props = defineProps<{
    billingDetails: Billing;
  }>();

  const user = computed((): User => {
    return $store.getters.authUser;
  });

  const show = ref(true);
  const showOverlay = ref(false);
  const showMainOverlay = ref(false);
  const paymentMethodMessage = ref("");

  const modalTitle = "Billing";

  onBeforeUnmount(() => {
    emitter.off("billing-management");
  });

  onMounted(async () => {
    // console.log(this.billingDetails)
    await nextTick();
    const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipElements.forEach((element) => {
      const tooltip = new Tooltip(element as HTMLElement, { trigger: "hover" });
      tooltipInstances.value.push(tooltip);
    });
  });

  function updatePlan() {
    emitter.emit("update-plan");
  }

  function cancelPlan() {
    emitter.emit("cancel-plan");
  }

  function addPayment() {
    emitter.emit("billing-add-payment");
  }

  async function makeDefault(idx: number, paymentMethod: string) {
    paymentMethodMessage.value = "Updating payment information...";
    showOverlay.value = true;
    const response = await setDefaultPayment({ paymentMethod });
    if (response?.status === "success") {
      props.billingDetails!.paymentMethods = response?.paymentMethods;
    }
    showOverlay.value = false;
  }

  async function deletePaymentMethod(idx: number, paymentMethod: string) {
    paymentMethodMessage.value = "Deleting selected payment method...";
    showOverlay.value = true;
    const response = await removePayment({ paymentMethod });
    if (response?.status === "success") {
      props.billingDetails!.paymentMethods = response?.paymentMethods;
    }
    showOverlay.value = false;
  }

  onBeforeUnmount(() => {
    tooltipInstances.value.forEach(tooltip => tooltip.dispose());
    tooltipInstances.value = [];
  });

</script>

<style scoped>
:deep .tooltip-inner {
  text-align: left;
}

#overlay {
  position: absolute;
  display: none;
  width: 98%;
  height: 98%;
  top: 0px;
  left: 8px;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.85);
  z-index: 3;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.payment-methods {
  min-height: 105px;
}

.action-button,
.action-button:focus .action-button:active {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.history-container {
  overflow: auto;
  max-height: 136px;
}

.billing-buttons {
  position: absolute;
  right: 0;
}

.overflow-ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.default-action {
  opacity: 0.5;
}

:deep .set-card-spacing {
  padding-right: 3rem !important;
}

:deep .set-expire-spacing {
  padding-right: 1.5rem !important;
}

:deep .b-table-sticky-header {
  max-height: 125px;
}

:deep .dropdown .btn {
  background-color: transparent !important;
  color: #495057 !important;
  border: none;
}

:deep .table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
  background-color: #fff !important;
}

h5 {
  font-family: "proximanova-medium", sans-serif !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.subscription-renewal {
  margin-top: -0.5rem;
  font-family: "proximanova-regular", sans-serif;
  font-size: 16px;
  white-space: nowrap;
}

.default {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}
</style>
