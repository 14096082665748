<template>
  <authentic-modal ref="companyDetailsModal" id="company-details-modal" :on-submit="submit" size="lg"
    :title="modalTitle">
    <company-details-form :adminType="'admin'" :isSubmitting="isSubmitting">
      <template v-slot:company-industy> </template>
      <template v-slot:name-input></template>
    </company-details-form>
    <template v-slot:modal-footer>
      <div class="btn-group pe-4 fix-buttons" role="group">
        <button class="btn btn-outline-secondary btn-sm rounded-pill ms-2" type="button"
          @click.prevent="cancel">Cancel</button>
        <button class="btn btn-primary btn-sm rounded-pill ms-2" type="button" @click.prevent="submit">Save</button>
      </div>
    </template>
  </authentic-modal>
</template>

<script setup lang="ts">
import { Company } from "@authentic-labs/vue-component-library";
import {
  fetchCompanyAdmin,
  updateCompanyAdmin,
} from "../../services/AdminService";
import AuthenticModal from "../shared/AuthenticModal.vue";
import CompanyDetailsForm from "./CompanyDetailsForm.vue";
import User from "../../models/User";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { computed, inject, markRaw, onMounted, ref, onBeforeMount } from "vue";
import { useToast } from "vue-toastification";

const emitter: any = inject("emitter");
const $store = useStore();
const toast = useToast();

const props = defineProps({
  isValid: Boolean,
});

const user = computed(() => {
  return $store.getters.authUser;
});
const company = computed(() => {
  return $store.getters.company;
});
const modalTitle = "Company details";
const logoFile: File | null = null;

const companyDetailsModal = ref<typeof AuthenticModal>();
const isSubmitting = ref(false);
const modalVisible = ref(false);

onBeforeMount(() => {
  emitter.on("company-details", async () => {
    const companyResponse = await fetchCompanyAdmin(company.value.companyId);
    await $store.dispatch("setCompanyStore", companyResponse);
    companyDetailsModal.value?.showModal();
  });
   
  emitter.on("submitCompanyDetails", (isValid: boolean) => {
    checkDetailsForm(isValid);
  });
});



function checkDetailsForm(isValid: boolean){
  if(isValid){
    updateCompanyDetails();
  } else {
  isSubmitting.value = false;
  }
}

function submit(){
  isSubmitting.value = true
}

async function updateCompanyDetails() {
  const companyUpdate = company.value
  try {
    await updateCompanyAdmin(company.value.companyId, companyUpdate);
    companyDetailsModal.value?.hideModal();
    toast.success("Company details for " +
        companyUpdate.companyName +
        " have been successfully update!")
  } catch {
    toast.error("Company update failed, please try again");
  }
  finally {
    isSubmitting.value = false;
  }
}

function showCompanyDetails() {
  companyDetailsModal.value?.showModal();
}

async function cancel() {
  if ($store.getters.companyLogoFile.filesId) {
    company.value.logoFile = $store.getters.companyLogoFile;
  }
  companyDetailsModal.value?.hideModal();
}
</script>

<style scoped>

  .fix-buttons {
    position: absolute;
    /* right: 20px; */
    bottom: 15px;
  }
</style>
