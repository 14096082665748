/**
 * Service for company API calls
 */
import { apiClient } from "./Api";
import axios from "axios";

export async function getAnalyticsData(companyId: number, dates: object) {
  // API is defined by AWS Gateway and is a trigger for a lambda function so a bit different than the other API calls
  // console.log(import.meta.env.VITE_ANALYTICS_API_URL);
  const dateRange = JSON.stringify(dates);
  const apiUrl = import.meta.env.VITE_ANALYTICS_API_URL + "/analytics-data";
  const { data } = await axios.get(`${apiUrl}?companyId=${companyId}&dateRange=${dateRange}`);
  return data;
}
